import React from "react";
import PropTypes from "prop-types";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import BackgroundImage from "gatsby-background-image";

const ContentSection = ({ item = {} }) => {
  return (
    <BackgroundImage
      Tag="section"
      {...item.image}
      style={{
        backgroundAttachment: "fixed",
      }}
    >
      <Box>
        <Box h="100vh" w="full">
          <Flex w="full" justifyItems="center" h="full">
            <Flex
              direction="column"
              h="full"
              w={["100vw", null, null, "50%"]}
              pl={["2", null, null, "36"]}
              pr={["2", null, null, "12"]}
              justifyItems="center"
              justifyContent="center"
              background="black"
              backgroundColor="rgba(0, 0, 0, 0.5)"
            >
              <Heading fontSize="5xl" fontWeight="bold" color="gray.300">
                {item.number}
              </Heading>
              <Text
                mb="10"
                color="white"
                fontSize={["4xl", "4xl", "5xl", "5xl"]}
                fontWeight="bold"
              >
                {item.title}
              </Text>
              {item.info && (
                <Text fontSize={["lg", null, "xl", "2xl"]} color="gray.300">
                  {item.info}
                  {item.info2 && (
                    <>
                      <br />
                      {item.info2}
                    </>
                  )}
                </Text>
              )}
            </Flex>
            <Box w="50%" h="full" display={["none", null, null, "block"]}></Box>
          </Flex>
        </Box>
      </Box>
    </BackgroundImage>
  );
};

export default ContentSection;

ContentSection.propTypes = {
  item: PropTypes.node,
};
