import React from "react";
import { useEffect } from "react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Box, Flex } from "@chakra-ui/react";
import "../styles/global.css";
import { useViewportScroll, useTransform, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import AllContentSections from "../components/AllContentSections";
import Partner from "../components/Partner";
import Hero from "../components/Hero";
import Svg from "../components/Svg";
import SlickCarousel from "../components/SlickCarousel";
import Kontakt from "../components/Kontakt";

const IndexPage = () => {
  const { scrollYProgress } = useViewportScroll();
  const scale = useTransform(scrollYProgress, [0, 0.17], [1, 0.5]);
  const opacity = useTransform(scrollYProgress, [0.15999, 0.16], [0, 1]);
  const hide = useTransform(scrollYProgress, [0.8, 0.84], [1, 0]);
  const zIndex = useTransform(scrollYProgress, [0.69999, 0.7], [700, 400]);
  const logic = useTransform(scrollYProgress, [0, 0.7], [900, 300]);
  const opacity1 = useTransform(scrollYProgress, [0.7, 0.7150001], [0, 1]);

  const { ref, inView } = useInView({
    threshold: 0.9,
  });
  console.log(ref);

  const animation = useAnimation();
  const removeAnimation = useAnimation();

  useEffect(() => {
    if (inView) {
      animation.start({
        y: 0,
        transition: {
          duration: 1,
          ease: "easeInOut",
        },
      });
      removeAnimation.start({
        y: "-25vh",
        transition: {
          duration: 0.7,
          ease: "easeInOut",
        },
      });
    }
    if (!inView) {
      animation.start({
        y: "100vh",
      });
      removeAnimation.start({
        originY: -0.5,
        originX: 0.4,

        y: 0,
        transition: {
          duration: 1,
          ease: "easeInOut",
        },
      });
    }
  }, [inView]);

  return (
    <Layout refrence={ref} animation={animation}>
      <SEO />
      <main>
        <Box className="">
          <Flex w="100%" d="flex" justifyContent="center" zIndex={0}>
            <Svg
              removeAnimation={removeAnimation}
              opacity={opacity}
              opacity1={opacity1}
              scale={scale}
              hide={hide}
              zIndex={zIndex}
              logic={logic}
            />
          </Flex>
          <Hero />
          <AllContentSections />
          <Partner />
          <SlickCarousel />
          <Kontakt></Kontakt>
        </Box>
      </main>
    </Layout>
  );
};

export default IndexPage;
