import * as React from "react";
import { Grid, Box, Flex, Text } from "@chakra-ui/react";
import { GatsbyImage } from "gatsby-plugin-image";
import "./partner.css";
import { motion, AnimatePresence } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";

export default function Partner() {
  const pageQuery = useStaticQuery(graphql`
    query {
      slideShow: allFile(
        filter: { relativeDirectory: { eq: "slideshow" } }
        sort: { fields: base, order: ASC }
      ) {
        edges {
          node {
            id
            base
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                quality: 70
                blurredOptions: { width: 100 }
                transformOptions: { fit: CONTAIN }
              )
            }
          }
        }
      }
    }
  `);

  const partnersURL = {
    ascaso: "https://www.ascaso.com",
    dalla_corte: "https://www.dallacorte.com",
    ecm: "https://www.ecm.de",
    eureka: "https://www.eureka.co.it",
    mahlkoenig: "https://www.mahlkoenig.de",
    vbm: "https://www.vbmespresso.com",
  };

  return (
    <Box id="partner" zIndex={500} position={"relative"}>
      <Box display={["none", "block", "block", "block"]} mt={40}>
        <Text
          fontSize={["4xl", "4xl", "5xl", "5xl"]}
          fontWeight="bold"
          align="center"
          my={16}
        >
          Unsere Partner
        </Text>
        <Grid
          px={[null, 10, 20, 40]}
          mx="auto"
          templateRows="repeat(2, 1fr)"
          templateColumns="repeat(3, 1fr)"
          gap={[null, 8, 8, 16, 20]}
        >
          {pageQuery.slideShow.edges.map(({ node }) => (
            <AnimatePresence key={node.id}>
              <motion.Box>
                <Flex
                  w="100%"
                  h={[null, 300, 300, 300, 300]}
                  _hover={{
                    opacity: "0.7",
                  }}
                  my="auto"
                  align="center"
                  justifyContent="center"
                  justify="center"
                  initial={{ opacity: 0.1 }}
                  animate={{ opacity: 1 }}
                >
                  <Box
                    as="a"
                    target="_blank"
                    href={partnersURL[node.base.slice(0, -4)]}
                  >
                    <GatsbyImage
                      image={node.childImageSharp?.gatsbyImageData}
                      className="size"
                      alt={node.base.split("-").join(" ").split(".")[0]}
                    />
                  </Box>
                </Flex>
              </motion.Box>
            </AnimatePresence>
          ))}
        </Grid>
      </Box>
    </Box>
  );
}
