import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {
  MailIcon,
  PhoneIcon,
  LocationMarkerIcon,
} from "@heroicons/react/outline";
import {
  Box,
  Text,
  Flex,
  Spacer,
  HStack,
  Icon,

  /*   Input,
  FormControl,
  Button,
  Textarea, */
} from "@chakra-ui/react";

export default function Kontakt() {
  const { site } = useStaticQuery(pageQuery);
  const { tel, domain } = site.siteMetadata;

  return (
    <Box position="relative" bg="white" id="contact" mt={40}>
      <Box position="relative" inset="0">
        <Box position="relative" insetY="0" left="0" width="50%" bg="white" />
      </Box>
      {/* <Box
          mx="auto"
          px={["4", "6", "6", "8"]}
          py={["16", null, null, "24"]}
          pl={[null, null, null, "12"]}
          w={["80%", "60%"]}
          bg="white"
          gridColumnStart="1"
          gridColumnEnd="6"
        >
          <Box mx="auto" maxW={["lg", null, null, "2xl"]}>
            <FormControl
              action="#"
              method="POST"
              display="grid"
              gridRowGap="6"
              gridColumn="1"
            >
              <Box>
                <Input
                  type="text"
                  name="full_name"
                  id="full_name"
                  autoComplete="name"
                  _placeholder={{
                    color: "rgb(145, 145, 145)",
                  }}
                  px="4"
                  py="3"
                  w="full"
                  border="2px"
                  borderColor="gray.300"
                  _focus={{
                    borderColor: "indigo.500",
                    ring: "2",
                    ringColor: "indigo.500",
                  }}
                  borderWidth="1px"
                  boxShadow="sm"
                  placeholder="Full name"
                />
              </Box>
              <Box>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  _placeholder={{
                    color: "rgb(145, 145, 145)",
                  }}
                  px="4"
                  py="3"
                  w="full"
                  border="2px"
                  borderColor="gray.300"
                  _focus={{
                    borderColor: "indigo.500",
                    ring: "2",
                    ringColor: "indigo.500",
                  }}
                  borderWidth="1px"
                  boxShadow="sm"
                  placeholder="Email"
                />
              </Box>
              <Box>
                <Input
                  type="text"
                  name="phone"
                  id="phone"
                  autoComplete="tel"
                  _placeholder={{
                    color: "rgb(145, 145, 145)",
                  }}
                  px="4"
                  py="3"
                  w="full"
                  border="2px"
                  borderColor="gray.300"
                  _focus={{
                    borderColor: "indigo.500",
                    ring: "2",
                    ringColor: "indigo.500",
                  }}
                  borderWidth="1px"
                  boxShadow="sm"
                  placeholder="Phone"
                />
              </Box>
              <Box>
                <Textarea
                  id="message"
                  name="message"
                  rows={4}
                  _placeholder={{
                    color: "gray.500",
                  }}
                  px="4"
                  py="3"
                  w="full"
                  border="1"
                  borderColor="gray.300"
                  borderRadius="6px"
                  boxShadow="sm"
                  _focus={{
                    ringColor: "indigo.500",
                    borderColor: "indigo.500",
                  }}
                  placeholder="Message"
                  defaultValue={""}
                />
              </Box>
              <Box>
                <Button
                  type="submit"
                  display="inline-flex"
                  ustifyItems="center"
                  justifyContent="center"
                  px="6"
                  py="3"
                  color="white"
                  fontSize="medium"
                  bgColor="gray.500"
                  _hover={{
                    bgColor: "gray.600",
                  }}
                  border="transparent"
                  borderRadius="6px"
                  boxShadow="sm"
                  _focus={{
                    outline: "none",
                    ring: "2px",
                    ringColor: "gray.500",
                    ringOffset: "2",
                  }}
                >
                  Submit
                </Button>
              </Box>
            </FormControl>
          </Box>
        </Box> */}
      {/*       <Box
        gridColumnStart="1"
        gridColumnEnd="6"
        px={["4", "6", "6", "8"]}
        py={["16", null, null, "24"]}
        bg="gray.50"
        pr={[null, null, null, null, "12"]}
      >
        <Box mx="auto">
          <Flex
            flexDir={["column", "column", "row"]}
            justify="space-around"
            w="full"
            alignItems="center"
            color="gray.500"
            experimental_spaceY={["4", "4", "0"]}
          >
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
              mx="auto"
              w={["75%", null, "33.33%", "80%"]}
            >
              <Box>
                <Text display="flex">
                  <LocationMarkerIcon
                    style={{
                      flexShrink: "0",
                      width: "24px",
                      height: "24px",
                      color: "gray.400",
                    }}
                    aria-hidden="true"
                  />
                  <Text ml="3">Norderstraße 37, 24939 Flensburg</Text>
                </Text>
              </Box>
            </Flex>
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
              mx="auto"
              w={["75%", null, "33.33%", "80%"]}
            >
              <Text display="flex">
                <PhoneIcon
                  style={{
                    flexShrink: "0",
                    width: "24px",
                    height: "24px",
                    color: "gray.400",
                  }}
                  aria-hidden="true"
                />
                <Text ml="3">
                  <a href={`tel:${tel[1]}`}>{tel[1]}</a>
                </Text>
              </Text>
            </Flex>
            <Flex
              direction="column"
              alignItems="center"
              justifyContent="center"
              justifyItems="center"
              mx="auto"
              w={["75%", null, "33.33%", "80%"]}
            >
              <Text display="flex">
                <MailIcon
                  style={{
                    flexShrink: "0",
                    width: "24px",
                    height: "24px",
                    color: "gray.400",
                  }}
                  aria-hidden="true"
                />
                <Text ml="3">
                  <a href={"mailto:cp@" + domain}>{"cp@" + domain}</a>
                </Text>
              </Text>
            </Flex>
          </Flex>
        </Box>
      </Box> */}
      <Flex
        direction={{ base: "column", xl: "row" }}
        align="center"
        bg="gray.50"
        color="gray.500"
        px={[4, null, null, null, 10, 32]}
        py={["16", null, null, "24"]}
        fontSize={["md", "xl", "2xl", null, null, "3xl"]}
      >
        <HStack spacing={2} py={{ base: "4", xl: "0" }}>
          <Icon as={LocationMarkerIcon} />
          <Text>Norderstraße 37, 24939 Flensburg</Text>
        </HStack>
        <Spacer />
        <HStack spacing={2} py={{ base: "4", xl: "0" }}>
          <Icon as={PhoneIcon} />
          <Text>
            <a href={`tel:${tel[1]}`}>{tel[1]}</a>
          </Text>
        </HStack>
        <Spacer />
        <HStack spacing={2} py={{ base: "4", xl: "0" }}>
          <Icon as={MailIcon} />
          <Text>
            <a href={"mailto:cp(at)" + domain}>{"cp(at)" + domain}</a>
          </Text>
        </HStack>
      </Flex>
    </Box>
  );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        domain
        tel
      }
    }
  }
`;
