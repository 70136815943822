import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";
import ContentSection from "./ContentSection";

const AllContentSections = () => {
  const { expresso, Kaffeem, Werkstatt, Ersatzteile } = useStaticQuery(query);
  const expressoImage = getImage(expresso);
  const KaffeemImage = getImage(Kaffeem);
  const WerkstattImage = getImage(Werkstatt);
  const ErsatzteileImage = getImage(Ersatzteile);
  const expressoBgImage = convertToBgImage(expressoImage);
  const KaffeemBgImage = convertToBgImage(KaffeemImage);
  const WerkstattBgImage = convertToBgImage(WerkstattImage);
  const ErsatzteileBgImage = convertToBgImage(ErsatzteileImage);

  const contentSection = [
    {
      title: "Espressomaschinen",
      number: "01",
      info: "Beratung Reparatur Verkauf Wartung",
      info2: null,
      image: expressoBgImage,
    },
    {
      title: "Kaffeemühlen",
      number: "02",
      info: null,
      info2: null,
      image: KaffeemBgImage,
    },
    {
      title: "Werkstatt",
      number: "03",
      info: "Reparatur und Wartung nahezu aller Marken von Siebträger-Espressomaschinen im nördlichen Schleswig-Holstein, der Westküste und im südlichen Dänemark",
      info2: null,
      image: WerkstattBgImage,
    },
    {
      title: "Ersatzteile",
      number: "04",
      info: "Fast alle benötigten Ersatzteile der Partner-Marken DallaCorte, ASCASO, Mahlkönig, EUREKA, ecm, VBM (Vibiemme) Reneka und bwt water+more sind vorrätig",
      info2:
        "Viele Standard Ersatz- und Verschleißteile aller übrigen Marken sind vorhanden oder können meist schnell besorgt werden",
      image: ErsatzteileBgImage,
    },
  ];

  return (
    <>
      {contentSection.map((item, index) => (
        <ContentSection key={`section-${index}`} item={item} />
      ))}
    </>
  );
};

export default AllContentSections;

const query = graphql`
  query {
    expresso: file(relativePath: { eq: "02.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
    Kaffeem: file(relativePath: { eq: "01.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
    Werkstatt: file(relativePath: { eq: "03.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
    Ersatzteile: file(relativePath: { eq: "04.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 2000, placeholder: BLURRED)
      }
    }
  }
`;
