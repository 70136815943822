import React, { useRef } from "react";
import Slider from "react-slick";
import { Box } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

const SlickCarousel = () => {
  const CarouselRef = useRef();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <Box mt={40}>
      <Box
        w="80%"
        mx="auto"
        my={10}
        display={["block", "none", "none", "none"]}
      >
        <Slider ref={CarouselRef} {...settings}>
          <Box
            as="a"
            href="https://www.dallacorte.com"
            target="_blank"
            px={10}
            alignItems="center"
          >
            <StaticImage
              src="../images/slideshow/dalla_corte.png"
              alt="Dalla Corte"
              placeholder="blurred"
            />
          </Box>
          <Box px={10} as="a" href="https://www.mahlkoenig.de" target="_blank">
            <StaticImage
              src="../images/slideshow/mahlkoenig.png"
              alt="Mahlkönig"
              placeholder="blurred"
            />
          </Box>
          <Box px={10} as="a" href="https://www.eureka.co.it" target="_blank">
            <StaticImage
              src="../images/slideshow/eureka.jpg"
              alt="Eureka"
              placeholder="blurred"
            />
          </Box>
          <Box px={10} as="a" href="https://www.ecm.de" target="_blank">
            <StaticImage
              src="../images/slideshow/ecm.png"
              alt="ECM"
              placeholder="blurred"
            />
          </Box>
          <Box
            px={10}
            as="a"
            href="https://www.vbmespresso.com"
            target="_blank"
          >
            <StaticImage
              src="../images/slideshow/vbm.png"
              alt="VBM"
              placeholder="blurred"
            />
          </Box>
          <Box px={10} as="a" href="https://www.ascaso.com" target="_blank">
            <StaticImage
              src="../images/slideshow/ascaso.png"
              alt="Ascaso"
              placeholder="blurred"
            />
          </Box>
        </Slider>
      </Box>
    </Box>
  );
};

export default SlickCarousel;
